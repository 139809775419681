/* ----------------------------------------------------------
POLÃTIA DE COOKIES
-------------------------------------------------------------*/

var CookiePolicy = function()
{
    function CloseCookieBox()
    {
        $('div.CookiesPolicyContent').stop().animate({opacity:0},300,function()
        {
            $(this).css({'display':'none'});
        });
    }
    
    function ControllCookiePolicy()
    {
        var userAgentString     = navigator.userAgent; 
        var definicoesCookies   = '';
    
        if(userAgentString.indexOf("Edg") > -1)
        {
            definicoesCookies = 'https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09';
        }
        else if(userAgentString.indexOf("Chrome") > -1)
        {
            definicoesCookies = 'https://support.google.com/chrome/answer/95647?hl=pt';
        }
        else if(userAgentString.indexOf("Firefox") > -1)
        {
            definicoesCookies = 'https://support.mozilla.org/pt-BR/kb/cookies-informacoes-sites-armazenam-no-computador';
        }
        else if(userAgentString.indexOf("Safari") > -1)
        {
            definicoesCookies = 'https://support.apple.com/pt-br/guide/safari/sfri11471/mac';
        }
        else if(userAgentString.indexOf("OP") > -1)
        {
            definicoesCookies = 'https://help.opera.com/en/latest/web-preferences/';
        }
        else if(userAgentString.indexOf("MSIE") > -1 ||  userAgentString.indexOf("rv:") > -1)
        {
            definicoesCookies = 'https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies';
        }
        
        // var CSS = '<style type="text/css">';
                
        //     CSS += '#CookiesPolicy {position:fixed; z-index:100; bottom:0; left:50%; transform:translateX(-50%); width:calc(100% - 30px); max-width:1030px; background:#f5f5f5; box-shadow:0 0 14px rgba(0,0,0,0.2)}';
        //     CSS += '#CookiesPolicy .wrappolicy {padding:20px 40px; display:flex; flex-flow:row wrap; justify-content:center; align-items:stretch;}';
            
        //     CSS += '#CookiesPolicy .left {width:calc(100% - 170px); text-align:left;}';
        //     CSS += '#CookiesPolicy .left h1 {font-family: "Barlow Semi Condensed",sans-serif; font-size:16px; line-height:normal; color:#000; font-weight:700; padding-bottom:10px}';
        //     CSS += '#CookiesPolicy .left p {font-family: "Barlow Semi Condensed",sans-serif; font-size:14px; line-height:17px; color:#444;}';
        //     CSS += '#CookiesPolicy .left a {display:inline; color:#000; font-weight:700; text-decoration:underline}';
            
        //     CSS += '#CookiesPolicy .right {width:170px; text-align:right; position:relative}';
        //     CSS += '#CookiesPolicy .right a.aceptCookie {display:inline-block; position:relative; margin-top:22px; width:150px; height:46px; line-height:46px; font-family: "Barlow Semi Condensed",sans-serif; font-size:14px; color:#fff; background-color:#4d9d56; text-align:center; transition:all 300ms ease;}';
        //     CSS += '#CookiesPolicy .right a.aceptCookie:hover {background:#4d9d56}';

        //     CSS += '#CookiesPolicy .right a.cookieDefinition {text-decoration:underline; margin-top:10px; font-size:13px; font-family: "Barlow Semi Condensed",sans-serif; display:inline-block; color:#000;}';
            
        //     CSS += '#CookiesPolicyBorder {position:fixed; z-index:100; bottom:0; left:0; width:100%; height:10px; background:#f5f5f5;}';

        //     CSS += '@media screen and (max-width:740px), screen and (max-width:820px) and (orientation:landscape){';
        //         CSS += '#CookiesPolicy .left h1 {text-transform:none;}';
        //         CSS += '#CookiesPolicy .left p {text-transform:none;}';
        //     CSS += '}';
        //     CSS += '@media screen and (max-width:520px){';
        //         CSS += '#CookiesPolicy .left {width:100%}';
        //         CSS += '#CookiesPolicy .left a {display:block;}';
        //         CSS += '#CookiesPolicy .right {width:100%; text-align:left;}';
        //         CSS += '#CookiesPolicy .right a, #CookiesPolicy .right a.aceptCookie {margin-top:15px;}';
        //         CSS += '#CookiesPolicy .right a.cookieDefinition {display:block}';
        //     CSS += '}';

        // CSS += '</style>';
        
        var cookieContent = $.ajax({
            //type: "post",
            url:'/politica/cookie',
            dataType: "json"
        });
        
        console.log( 'teste!' );

        cookieContent.done(function(result,statusText,xhr)
        {
            if(xhr.status == 200)
            {
                var htmlCookieBox = '<div id="CookiesPolicy" class="CookiesPolicyContent">';
                     htmlCookieBox += '<div class="wrappolicy">';
                        htmlCookieBox += '<div class="left">'+result.conteudo+'</div>';
                        htmlCookieBox += '<div class="right">';
                            htmlCookieBox += '<a href="#" class="aceptCookie">ACEITO</a>';
                            htmlCookieBox += (definicoesCookies != '') ? '<a href="'+definicoesCookies+'" class="cookieDefinition" target="_blank">Ver definiÃ§Ãµes de cookies</a>' : '';
                        htmlCookieBox += '</div>';
                    htmlCookieBox += '</div>';
                htmlCookieBox += '</div>';
                
                if(!window.localStorage.getItem(result.cookiename) || window.localStorage.getItem(result.cookiename) == null)
                {
                    $('body').append(htmlCookieBox);
                    
                    $('a.aceptCookie').click(function(e)
                    {
                        e.preventDefault();
            
                        CloseCookieBox();
            
                        window.localStorage.setItem(result.cookiename, 1);
                    });
                    
                    // $.fancybox.open({
                    // 	src  : htmlCookieBox,
                    // 	type : 'inline',
                    // 	opts : {
                    // 	    arrows: false,
                    // 	    preventCaptionOverlap: false,
                    // 	    keyboard: true,
                    // 		afterShow : function( instance, current ) {
                    // 			$('a.aceptCookie').click(function(e)
                    //             {
                    //                 e.preventDefault();
                        
                    //                 CloseCookieBox();
                        
                    //                 window.localStorage.setItem(result.cookiename, 1);
                    //             });
                    // 		}
                    // 	}
                    // });
                
                    //window.localStorage.removeItem('webitcoin_cookie_acept');
                
                }
            }
        });
    }
    
    ControllCookiePolicy();
    
//     $.fancybox.open('<div class="message"><h2>Hello!</h2><p>You are awesome!</p></div>',
//     {
//         opts : {
//     		afterShow : function( instance, current ) {
//     			alert( 'done!' );
//     		}
//         }
// 	});
    
    
}