
/* ----------------------------------------------------------
VALIDAÃ‡ÃƒO DE FORMULÃRIOS
-------------------------------------------------------------*/

var FormValidation = function()
{
	function validateEmail($email)
	{
	  var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
	  return emailReg.test( $email );
	}

	function validateCPF(cpf_field)
	{
		var cpf = cpf_field.replace(/[^0-9]/g, '').toString();

		if( cpf.length == 11 )
        {
            var v = [];

            //Calcula o primeiro dÃ­gito de verificaÃ§Ã£o.
            v[0] = 1 * cpf[0] + 2 * cpf[1] + 3 * cpf[2];
            v[0] += 4 * cpf[3] + 5 * cpf[4] + 6 * cpf[5];
            v[0] += 7 * cpf[6] + 8 * cpf[7] + 9 * cpf[8];
            v[0] = v[0] % 11;
            v[0] = v[0] % 10;

            //Calcula o segundo dÃ­gito de verificaÃ§Ã£o.
            v[1] = 1 * cpf[1] + 2 * cpf[2] + 3 * cpf[3];
            v[1] += 4 * cpf[4] + 5 * cpf[5] + 6 * cpf[6];
            v[1] += 7 * cpf[7] + 8 * cpf[8] + 9 * v[0];
            v[1] = v[1] % 11;
            v[1] = v[1] % 10;

            //Retorna Verdadeiro se os dÃ­gitos de verificaÃ§Ã£o sÃ£o os esperados.
            if ( (v[0] != cpf[9]) || (v[1] != cpf[10]) )
            {
                return false;
            }
            else
            {
            	return true;
            }
        }
        else
        {
           return false;
        }
	}

	this.testFields = function(form)
	{
		var range = 0,
			mensagem = '<ul>';
			
		form.find('.requiredField').each(function()
		{
			$(this).removeClass('error');
			$(this).parent().removeClass('error');
			
			var field = $(this),
				title = $(this).attr('title'),
				fieldName = $(this).attr('name');
			
			switch(field.attr('type'))
			{
				case 'text':
					if(fieldName == 'cpf')
					{
						if(validateCPF(field.val()) == false)
						{
							$(this).addClass('error');
							mensagem += '<li>O campo "'+title+'" estÃ¡ invÃ¡lido</li>';
							range++;
						}
					}
					else
					{
						if(field.val().length <= 0)
						{
							$(this).addClass('error');
							mensagem += '<li>O campo "'+title+'" nÃ£o pode ser vazio</li>';
							range++;
						}
					}
				break;

				case 'email':
					if( field.val().length <= 0 || !validateEmail( field.val() ) )
					{
						$(this).addClass('error');
						mensagem += '<li>O campo "'+title+'" estÃ¡ vazio ou incorreto</li>';
						range++;
					}
				break;

				case 'textarea':
					if( field.val().length <= 0 )
					{
						$(this).addClass('error');
						mensagem += '<li>O campo "'+title+'" Ã© obrigatÃ³rio</li>';
						range++;
					}
				break;

				case 'password':
					if( field.val().length <= 0 )
					{
						$(this).addClass('error');
						mensagem += '<li>O campo de "'+title+'" estÃ¡ incorreto</li>';
						range++;
					}
				break;

				case 'search':
					if( field.val().length <= 0 )
					{
						$(this).addClass('error');
						mensagem += '<li>O campo de "'+title+'" nÃ£o pode ser vazio</li>';
						range++;
					}
				break;

				case 'select':
					if( parseInt(field.val()) <= 0 )
					{
						$(this).parent().addClass('error');
						mensagem += '<li>O campo de "'+title+'" Ã© obrigatÃ³rio</li>';
						range++;
					}
				break;
				
				case 'checkbox':
					
					var total = 0;
					
					field.find('input[type="checkbox"]').each(function()
					{
						if( $(this).is(':checked') )
						{
							total++;
						}
					});
					
					if( total <= 0 )
					{
						$(this).addClass('error');
						mensagem += '<li>O campo de '+title+' Ã© obrigatÃ³rio</li>';
						range++;
					}

				break;

				case 'radio':
					
					var total = 0;
					
					field.find('input[type="radio"]').each(function()
					{
						if( $(this).is(':checked') )
						{
							total++;
						}
					});
					
					if( total <= 0 )
					{
						$(this).addClass('error');
						mensagem += '<li>O campo de '+title+' Ã© obrigatÃ³rio</li>';
						range++;
					}
					
				break;
			}
		});

		mensagem += '</ul>';
		
		if(range > 0)
		{
			return {
				status 	 : false,
				mensagem : '<div class="alertBox formError"><h1>Erro no FormulÃ¡rio.</h1><ul class="listError" role="list" aria-label="Lista de erros de preenchimento">'+mensagem+'</ul><a href="#" class="closeAlert" title="Fechar Mensagem"><i class="icon-cancel"></i></a></div>'
			};
		}
		else
		{
			return {
				status 	 : true,
				mensagem : ''
			};
		}
	}

	this.testRecaptcha = function(recaptcha)
	{
		if( recaptcha && recaptcha.length > 10 && recaptcha != '' )
		{
			return {
				status 	 : true,
				mensagem : ''
			};
		}
		else
		{
			return {
				status 	 : false,
				mensagem : '<div class="alertBox formError"><h1>Erro na ValidaÃ§Ã£o.</h1><ul class="listError" role="list" aria-label="Lista de erros de preenchimento"><li>Houve um erro na validaÃ§Ã£o do recaptcha. Por favor, tente novamente.</li></ul><a href="#" class="closeAlert" title="Fechar Mensagem"><i class="icon-cancel"></i></a></div>'
			};
		}
	}

	this.manualMessages = function(form,type,msg)
	{
		form.find('.alertsMessages').html('<div class="alertBox '+type+'">'+msg+'<a href="#" class="closeAlert" title="Fechar Mensagem"><i class="icon-cancel"></i></a></div>');
	}

	this.alertMessages = function(form)
	{
		var alertsMessages 	= form.find('.alertsMessages'),
			closeAlert 		= alertsMessages.find('a.closeAlert'),
			altura 			= alertsMessages.find('.alertBox').innerHeight();

		alertsMessages.stop().animate({height : altura + 30, opacity : 1},300,function()
		{
			$('html,body').stop().animate({ scrollTop : alertsMessages.offset().top - 110},1000);
		});

		alertsMessages.find('.listError').focus();

		closeAlert.on('click',function(e)
		{
			e.preventDefault();

			alertsMessages.stop().animate({height : 0, opacity : 0},300);
		});
	}
}

/* ----------------------------------------------------------
FORMULÃRIOS
-------------------------------------------------------------*/

var captchaComercializacaoLojista;
var captchaComercializacaoAnunciar;
var captchaClubeEmpresaForm;
var captchaFormContato;
var captchaFormNewsletter;
var captchaTrabalheConosco;

var _teste;

function myCallBack()
{
	// setTimeout( function() {
	if( $('#formComercializacaoLojista').length > 0)
	{
		var sitekey = $('#formComercializacaoLojista').data('key');

		captchaComercializacaoLojista = grecaptcha.render('reCaptchaSimuladorLojista',
		{
			'sitekey' : sitekey,
			'theme' : 'light'
		});
		
		_fnEnviaFormulariosFinal
		(
			{
				method 		: 'post',
				formulario 	: 'formComercializacaoLojista',
				recaptcha 	: captchaComercializacaoLojista
			}
		);
	}
	
	if( $('#formComercializacaoAnunciar').length > 0)
	{
		var sitekey = $('#formComercializacaoAnunciar').data('key');

		captchaComercializacaoAnunciar = grecaptcha.render('reCaptchaSimuladorAnunciar',
		{
			'sitekey' : sitekey,
			'theme' : 'light'
		});
		
		_fnEnviaFormulariosFinal
		(
			{
				method 		: 'post',
				formulario 	: 'formComercializacaoAnunciar',
				recaptcha 	: captchaComercializacaoAnunciar
			}
		);
	}

	if( $('#formComercializacaoEspaco').length > 0)
	{
		var sitekey = $('#formComercializacaoEspaco').data('key');

		captchaComercializacaoEspaco = grecaptcha.render('reCaptchaSimuladorEspaco',
		{
			'sitekey' : sitekey,
			'theme' : 'light'
		});
		
		_fnEnviaFormulariosFinal
		(
			{
				method 		: 'post',
				formulario 	: 'formComercializacaoEspaco',
				recaptcha 	: captchaComercializacaoEspaco
			}
		);
	}
	
	if( $('#formClubeEmpresa').length > 0)
	{
		var sitekey = $('#formClubeEmpresa').data('key');

		captchaClubeEmpresaForm = grecaptcha.render('reCaptchaClubeEmpresa',
		{
			'sitekey' : sitekey,
			'theme' : 'light'
		});
	}

	if( $('#formContato').length > 0 )
	{
		var sitekey = $('#formContato').data('key');

		captchaFormContato = grecaptcha.render('reCaptchaFormContato',
		{
			'sitekey' : sitekey,
			'theme' : 'light'
		});
		
		_fnEnviaFormulariosFinal
		(
			{
				method 		: 'post',
				formulario 	: 'formContato',
				recaptcha 	: captchaFormContato
			}
		);
	}

	if( $('#formProtecaoDados').length > 0 )
	{
		var sitekey = $('#formProtecaoDados').data('key');

		captchaFormProtecaoDados = grecaptcha.render('reCaptchaFormProtecaoDados',
		{
			'sitekey' : sitekey,
			'theme' : 'light'
		});

		// $('.cpfFieldProtecao').change(function() {
		// 	var cpfValue = $(this).val()
		// 	var assuntoStringFixa = $(".assuntoFieldProtecao").val().split(":")[0];

		// 	$(".assuntoFieldProtecao").val(assuntoStringFixa + ' : ' + cpfValue);
		// });
		
		ProtecaoDadosSubmitForm
		(
			{
				method 		: 'post',
				formulario 	: 'formProtecaoDados',
				recaptcha 	: captchaFormProtecaoDados
			}
		);
	}


	if( $('#formNewsletter').length > 0 )
	{
		var sitekey = $('#formNewsletter').data('key');
		
		captchaFormNewsletter = grecaptcha.render('reCaptchaNewsletter',
		{
			'sitekey' : sitekey,
			'theme' : 'light'
		});
		
		enviaFormularios
		(
			{
				method 		: 'post',
				formulario 	: 'formNewsletter',
				recaptcha 	: captchaFormNewsletter
			}
		);
	}
	
	if( $('#formTrabalheConosco').length > 0 )
	{
		var sitekey = $('#formTrabalheConosco').data('key');
		
		captchaTrabalheConosco = grecaptcha.render('reCaptchaTrabalheConosco',
		{
			'sitekey' : sitekey,
			'theme' : 'light'
		});
		
		TrabalheConoscoForm
		(
			{
				method 		: 'post',
				formulario 	: 'formTrabalheConosco',
				recaptcha 	: captchaTrabalheConosco
			}
		);
		// enviaFormularios
		// (
		// 	{
		// 		method 		: 'post',
		// 		formulario 	: 'formTrabalheConosco',
		// 		recaptcha 	: captchaTrabalheConosco
		// 	}
		// );
	}
	
	// }, 3000);
};

var _fnEnviaFormulariosFinal = function(settings)
{
	var validacao = new FormValidation();
	 
	var $form = $('#'+settings.formulario);

	$form.find('.captcha').find('.submit').html('<button form="'+settings.formulario+'" type="submit" class="btn big full verde">Enviar</button><input type="reset" value="Resetar formulÃ¡rio" hidden="hidden">');
	$form.attr('action', $form.data('action') );

	$form.on('submit',function(e)
	{
		e.preventDefault();

		var recaptcha = grecaptcha.getResponse(settings.recaptcha);
		var retornoValidacao = validacao.testFields($form);

		$form.find('.captcha .submit button.btn.verde').addClass('sending');

		
		/* VALIDA OS CAMPOS OBRIGATÃ“RIOS */
			
		if( retornoValidacao.status == true )
		{	
			/* VALIDA O RECAPTCHA */

			if( validacao.testRecaptcha(recaptcha).status == true )
			{

				/* ----------------------------------- AQUI VAI O AJAX ----------------------------------- */

				var sendFormAction = jQuery.ajax(
				{
					type: "post",
					url: $form.data('action'),
					data: $form.serialize(),
					//dataType: "json"
				});

				sendFormAction.done(function(result,statusText,xhr)
				{
					console.log(xhr.status)
					if(xhr.status == 200)
					{
						var msg = '<h1>SolicitaÃ§Ã£o efetuada com sucesso!</h1>';
							msg += (JSON.parse(xhr.responseText) && JSON.parse(xhr.responseText) != "") ? '<p>'+JSON.parse(xhr.responseText)+'</p>' : '';

						validacao.manualMessages($form,'formSucesso',msg);
					}
					else
					{
						var msg = '<h1>Houve um erro em sua solicitaÃ§Ã£o!</h1>';
							msg += '<p>'+xhr.statusText+'</p>';

						validacao.manualMessages($form,'formError',msg);
						
					}
					
					validacao.alertMessages($form);
					
					grecaptcha.reset();
				
					$form.find('input[type="reset"]').trigger('click');
					$form.find('.captcha .submit button.btn.verde').removeClass('sending');
				});	
				
				sendFormAction.fail(function(error)
				{
					grecaptcha.reset();
					
					var arrayError = JSON.parse(error.responseText);
					
					console.log('FAIL do AJAX')
					console.log(arrayError)
					
					var $msgs = '<h1>Houve um erro em sua solicitaÃ§Ã£o!</h1>';
					
					if(arrayError && arrayError.length > 0)
					{
						$msgs += '<ul>';
						
						for(var i = 0; i < arrayError.length; i++)
						{
							$msgs += '<li>'+arrayError[i]+'</li>';
						}
						
						$msgs += '</ul>';
					}
					
					validacao.manualMessages($form,'formError',$msgs);
					validacao.alertMessages($form);

					$form.find('.captcha .submit button.btn.verde').removeClass('sending');
				});
			}
			else
			{
				e.preventDefault();
				
				grecaptcha.reset();

				$form.find('.alertsMessages').html(validacao.testRecaptcha(recaptcha).mensagem);

				validacao.alertMessages($form);

				$form.find('.captcha .submit button.btn.verde').removeClass('sending');
			}
		}
		else
		{
			e.preventDefault();
			
			grecaptcha.reset();

			$form.find('.alertsMessages').html(retornoValidacao.mensagem);

			validacao.alertMessages($form);

			$form.find('.captcha .submit button.btn.verde').removeClass('sending');
		}
	});
}

var enviaFormularios = function(settings)
{
	var validacao = new FormValidation();
	 
	var $form = $('#'+settings.formulario);

	$form.find('.captcha').find('.submit').html('<button form="'+settings.formulario+'" type="submit" class="btn big full verde">Enviar</button><input type="reset" value="Resetar formulÃ¡rio" hidden="hidden">');
	$form.attr('action', $form.data('action') );

	if(settings.formulario != 'formNewsletter')
	{
		$form.on('submit',function(e)
		{
			$form.find('.captcha .submit button.btn.verde').addClass('sending');
			
			var recaptcha = grecaptcha.getResponse(settings.recaptcha);
			var retornoValidacao = validacao.testFields($form);
			
			/* VALIDA OS CAMPOS OBRIGATÃ“RIOS */
			
			if( retornoValidacao.status == true )
			{
				/* VALIDA O RECAPTCHA */
				if( validacao.testRecaptcha(recaptcha).status == true )
				{
					return true;
				}
				else
				{
					e.preventDefault();

					$form.find('.alertsMessages').html(validacao.testRecaptcha(recaptcha).mensagem);

					validacao.alertMessages($form);
				}

				$form.find('.captcha .submit button.btn.verde').removeClass('sending');
			}
			else
			{
				e.preventDefault();
				
				$form.find('.alertsMessages').html(retornoValidacao.mensagem);
				validacao.alertMessages($form);

				$form.find('.captcha .submit button.btn.verde').removeClass('sending');
			}
		});
	}
	else
	{
		/* ----------------------------------------------------------
			
		FORMULÃRIO DE NEWSLETTER
			
		------------------------------------------------------------ */

		$form.on('submit',function(e)
		{
			e.preventDefault();

			var recaptcha = grecaptcha.getResponse(settings.recaptcha);
			var retornoValidacao = validacao.testFields($form);

			$form.find('.captcha .submit button.btn.verde').addClass('sending');

			
			/* VALIDA OS CAMPOS OBRIGATÃ“RIOS */
				
			if( retornoValidacao.status == true )
			{	
				/* VALIDA O RECAPTCHA */
	
				if( validacao.testRecaptcha(recaptcha).status == true )
				{

					/* ----------------------------------- AQUI VAI O AJAX ----------------------------------- */

					var registerNewsletter = $.ajax({
				            type: "post",
				            url: $form.data('action'),
				            data: $form.serialize(),
				            dataType: "json"
				        });

				        registerNewsletter.done(function(result,statusText,xhr)
				        {
				        	if(result.OcorreuErro)
				        	{
				        		var msg = '<h1>Ocorreu um erro ao enviar seu cadastro:</h1>';
							    msg += '<p>'+result.mensagem+'</p>';

								validacao.manualMessages($form,'formError',msg);
				        	}
				        	else
				        	{
				        		var msg = '<h1>Cadastro realizado com sucesso!</h1>';
							    msg += '<p>'+result.mensagem+'</p>';

								validacao.manualMessages($form,'formSucesso',msg);
								
								$form.find('input[type="reset"]').trigger('click');
							}
							
							validacao.alertMessages($form);

							$form.find('.captcha .submit button.btn.verde').removeClass('sending');
				        	
				        	grecaptcha.reset();
		                });	
						
						registerNewsletter.fail(function(error)
						{
							
							grecaptcha.reset();
							var $msgs = '<p><strong>Ocorreu um erro ao enviar seu cadastro. Por favor, tente novamente mais tarde.</strong></p>';
								$msgs += '<p>'+jQuery.parseJSON(error.responseText)[0].message+'</p>';
		                    console.log($msgs);
		                    validacao.manualMessages($form,'formError',$msgs);
							validacao.alertMessages($form);

							$form.find('.captcha .submit button.btn.verde').removeClass('sending');
						});
				}
				else
				{
					e.preventDefault();
	
					$form.find('.alertsMessages').html(validacao.testRecaptcha(recaptcha).mensagem);
	
					validacao.alertMessages($form);

					$form.find('.captcha .submit button.btn.verde').removeClass('sending');
				}
			}
			else
			{
				e.preventDefault();

				$form.find('.alertsMessages').html(retornoValidacao.mensagem);

				validacao.alertMessages($form);

				$form.find('.captcha .submit button.btn.verde').removeClass('sending');
			}
		});
	}
}

var ProtecaoDadosSubmitForm = function(settings)
{
	var validacao = new FormValidation();
	 
	var $form = $('#'+settings.formulario);

	$form.find('.captcha').find('.submit').html('<button form="'+settings.formulario+'" type="submit" class="btn big full verde">Enviar</button><input type="reset" value="Resetar formulÃ¡rio" hidden="hidden">');
	$form.attr('action', $form.data('action') );
	

	$form.on('submit',function(e)
	{
		e.preventDefault();

		var recaptcha = grecaptcha.getResponse(settings.recaptcha);
		var retornoValidacao = validacao.testFields($form);

		$form.find('.captcha .submit button.btn.verde').addClass('sending');

		
		/* VALIDA OS CAMPOS OBRIGATÃ“RIOS */
			
		if( retornoValidacao.status == true )
		{	
			/* VALIDA O RECAPTCHA */

			if( validacao.testRecaptcha(recaptcha).status == true )
			{

				/* ----------------------------------- AQUI VAI O AJAX ----------------------------------- */

				var cleanDadosProtecao = jQuery.ajax({
			            type: "post",
			            url: $form.data('action'),
			            data: $form.serialize(),
			            //dataType: "json"
			        });

			        cleanDadosProtecao.done(function(result,statusText,xhr)
			        {
			        	if(xhr.status == 200)
			        	{
			        		var msg = '<h1>SolicitaÃ§Ã£o efetuada com sucesso!</h1>';
								msg += (JSON.parse(xhr.responseText) && JSON.parse(xhr.responseText) != "") ? '<p>'+JSON.parse(xhr.responseText)+'</p>' : '';

							validacao.manualMessages($form,'formSucesso',msg);
			        	}
			        	else
			        	{
			        		var msg = '<h1>Houve um erro em sua solicitaÃ§Ã£o!</h1>';
								msg += '<p>'+xhr.statusText+'</p>';

							validacao.manualMessages($form,'formError',msg);
			        		
			        	}
	                 	
	                 	validacao.alertMessages($form);
	                 	
	                 	grecaptcha.reset();
					
						$form.find('input[type="reset"]').trigger('click');
						$form.find('.captcha .submit button.btn.verde').removeClass('sending');
					});	
					
					cleanDadosProtecao.fail(function(error)
					{
						grecaptcha.reset();
						
						var arrayError = JSON.parse(error.responseText);
						
						console.log('FAIL do AJAX')
						console.log(arrayError)
						
						var $msgs = '<h1>Houve um erro em sua solicitaÃ§Ã£o!</h1>';
						
						if(arrayError && arrayError.length > 0)
						{
							$msgs += '<ul>';
							
							for(var i = 0; i < arrayError.length; i++)
							{
								$msgs += '<li>'+arrayError[i]+'</li>';
							}
							
							$msgs += '</ul>';
						}
						
						validacao.manualMessages($form,'formError',$msgs);
						validacao.alertMessages($form);

						$form.find('.captcha .submit button.btn.verde').removeClass('sending');
					});
			}
			else
			{
				e.preventDefault();
				
				grecaptcha.reset();

				$form.find('.alertsMessages').html(validacao.testRecaptcha(recaptcha).mensagem);

				validacao.alertMessages($form);

				$form.find('.captcha .submit button.btn.verde').removeClass('sending');
			}
		}
		else
		{
			e.preventDefault();
			
			grecaptcha.reset();

			$form.find('.alertsMessages').html(retornoValidacao.mensagem);

			validacao.alertMessages($form);

			$form.find('.captcha .submit button.btn.verde').removeClass('sending');
		}
	});
}

var cadastroClubeEmpresa = function()
{
	var validacao = new FormValidation();
	var $form = $('#formClubeEmpresa');
	var $alertsMessages = $form.find('.alertsMessages');

	$form.find('.captcha').find('.submit').html('<button form="formClubeEmpresa" type="submit" class="btn big full verde">Enviar</button><input type="reset" value="Resetar formulÃ¡rio" hidden="hidden">');
	$form.attr('action', $form.data('action') );

	$form.find('input[type="file"]').on('change',function()
	{
		$form.find('span.filename').text( $(this).val() );
	});

	$form.on('submit',function(e)
	{
		e.preventDefault();

		var recaptcha = grecaptcha.getResponse(captchaClubeEmpresaForm);
		var retornoValidacao = validacao.testFields($form);

		var $this = $form;

		$form.find('.captcha .submit button.btn.verde').addClass('sending');
		
		/* VALIDA OS CAMPOS OBRIGATÃ“RIOS */
		
		if( retornoValidacao.status == true)
		{
			/* VALIDA O RECAPTCHA */
			
			if( validacao.testRecaptcha(recaptcha).status == true  )
			{
				$.ajax(
				{
		            type 		: 'POST',
		            url 		: $this.data('action'),
					data 		: new FormData($this[0]),
				 	cache 		: false,
					contentType : false,
					processData : false,
		            success 	: function (data)
		            {
	            	 	grecaptcha.reset();
	            		$this.find('input[type="reset"]').trigger('click');
	                 	
	                 	var msg = '<h1>Dados enviados com sucesso!</h1>';
						    msg += '<p>Em breve um de nossos representantes entrarÃ¡ em contato para dar continuidade ao processo de cadastro.</p>';

						validacao.manualMessages($form,'formSucesso',msg);
						validacao.alertMessages($form);

						$form.find('.captcha .submit button.btn.verde').removeClass('sending');
		            },
					error 		: function(data)
					{
					 	grecaptcha.reset();
						$msgs = jQuery.parseJSON(data.responseText);
	                    
	                    var errorMsg = '<h1>Erro ao enviar dados</h1>';
		                    
	                    $.each($msgs.error, function (index, value)
	                    {
	                        $('input[name="' + index + '"').addClass('error');
	                    });

		                validacao.manualMessages($form,'formError',$msgs);
						validacao.alertMessages($form);

						$form.find('.captcha .submit button.btn.verde').removeClass('sending');
					}
	        	});
			}
			else
			{
				$form.find('.alertsMessages').html(validacao.testRecaptcha(recaptcha).mensagem);
				
				validacao.alertMessages($form);

				$form.find('.captcha .submit button.btn.verde').removeClass('sending');
			}
		}
		else
		{	
			$form.find('.alertsMessages').html(retornoValidacao.mensagem);

			validacao.alertMessages($form);

			$form.find('.captcha .submit button.btn.verde').removeClass('sending');
		}
	});
}

var TrabalheConoscoForm = function(settings) {
	
	/* ----------------------------------------------------------
			
		FORMULÃRIO DE TRABALHE CONOSCO
		
	------------------------------------------------------------ */

	var validacao = new FormValidation();
	 
	var $form = $('#'+settings.formulario);

	$form.find('.captcha').find('.submit').html('<button form="'+settings.formulario+'" type="submit" class="btn big full verde">Enviar</button><input type="reset" value="Resetar formulÃ¡rio" hidden="hidden">');
	$form.attr('action', $form.data('action') );
	

	/* ----------------------------------------------------------
			
		FORMULÃRIO CURRÃCULO
		
	------------------------------------------------------------ */
	
	if(settings.formulario == 'formTrabalheConosco') {
		$form.on('submit',function(e)
		{
			e.preventDefault();
	
			var recaptcha = grecaptcha.getResponse(settings.recaptcha);
			var retornoValidacao = validacao.testFields($form);
			
			var $this = $form;
			
			$form.find('.captcha .submit button.btn.verde').addClass('sending');
	
			/* VALIDA OS CAMPOS OBRIGATÃ“RIOS */
			
			if( retornoValidacao.status == true)
				{
				/* VALIDA O RECAPTCHA */
				
				if( validacao.testRecaptcha(recaptcha).status == true  )
				{
					$.ajax(
					{
			            type 		: 'POST',
			            url 		: $this.data('action'),
						data 		: new FormData($this[0]),
					 	cache 		: false,
						contentType : false,
						processData : false,
			            success 	: function (data)
			            {
		            	 	grecaptcha.reset();
		            		$this.find('input[type="reset"]').trigger('click');
		                 	
		                 	var msg = '<h1>Dados enviados com sucesso!</h1>';
							    msg += '<p>Obrigado. Seus dados foram cadastrados com sucesso em nossa base.</p>';
	
							validacao.manualMessages($form,'formSucesso',msg);
							validacao.alertMessages($form);
	
							$form.find('.captcha .submit button.btn.verde').removeClass('sending');
			            },
						error 		: function(data)
						{
						 	grecaptcha.reset();
							$msgs = jQuery.parseJSON(data.responseText);


		                    var errorMsg = '<h1>Erro ao enviar dados</h1>';
		                    
		                    $.each($msgs, function (index, value)
		                    {
		                    	console.log(value);
		                    	errorMsg += '<p>' + value + '</p>';
		                    });
	
			                validacao.manualMessages($form,'formError',errorMsg);
							validacao.alertMessages($form);
	
							$form.find('.captcha .submit button.btn.verde').removeClass('sending');
						}
		        	});
				}
				else
				{
					$form.find('.alertsMessages').html(validacao.testRecaptcha(recaptcha).mensagem);
					
					validacao.alertMessages($form);
	
					$form.find('.captcha .submit button.btn.verde').removeClass('sending');
				}
			}
			else
			{	
				$form.find('.alertsMessages').html(retornoValidacao.mensagem);
	
				validacao.alertMessages($form);
	
				$form.find('.captcha .submit button.btn.verde').removeClass('sending');
			}
		});
	}
}


