/* ------------------------------------------
SLIDES - SWIPERS
-------------------------------------------- */

var sliders = function()
{
	/* BANNER HOME */

	var totalItens = 0;

	$('#bannerHome ul li').each(function()
	{
		totalItens++;
	});

	if( totalItens > 1 )
	{
		var autoplay = $("#bannerHome").data("autoplay");
		
		if(autoplay === "1" || autoplay === 1) autoplay = true;
		else autoplay = false;

		var owl = $("#bannerHome .owl-carousel").owlCarousel(
		{
			items : 1,
			margin : 0,
			loop : true,
			mouseDrag : true,
			touchDrag : true,
			autoplay : autoplay,
			autoplayTimeout: 5000,
			autoplaySpeed: 2000,
			dotsSpeed: 2000,
			dragEndSpeed: 2000,
			nav: true,
			navSpeed: 2000,
			onDragged: callbackDrag,
			triggerable: callbackDrag
			//info: true,
		});
	}
	else
	{
		var owl = $("#bannerHome .owl-carousel").owlCarousel(
		{
			items : 1,
			margin : 0,
			loop : false,
			mouseDrag : false,
			touchDrag : false,
			autoplay : false,
			nav: false,
		});
	}

	

	function callbackDrag()
	{
		owl.trigger('stop.owl.autoplay');
	}

	$('#bannerHome .owl-nav button').on('click',function()
	{
		callbackDrag();
	});

	/* CARROSSEL CINEMA */

	$('#cinemaHome .owl-carousel').owlCarousel(
	{
		margin:4,
		loop: true,
	    nav: true,
	    mouseDrag: true,
		touchDrag: true,
		navSpeed: 2000,
		responsive:{
	        0:{
	            items:1,
	            center:true,
	            margin:5,
	            loop:true,
	        },
	        640:{
	            items:3
	        },
	        1000:{
	            items:4
	        },
	        1440:{
	            items:4
	        },
	        1680:{
	            items:6
	        }
	    }
	});


	/* GALERIA DE FOTOS */

	var gallery = $('.fotoGallery.owl-carousel').owlCarousel(
	{
	    margin:0,
	    nav:true,
	    items:1,
	    loop : true,
	});

	/* TIMELINE */

	$('#timeline .owl-carousel').owlCarousel(
	{
		margin:3,
		loop: false,
	    nav: true,
	    mouseDrag: true,
		touchDrag: true,
		navSpeed: 1000,
		responsive:{
	        0:{
	            center:true,
	            items:1,
	            margin:1,
	        },
	        568:{
	        	margin:1,
	            items:2
	        },
	        993:{
	            items:3
	        },
	        1281:{
	            items:4
	        }
	    }
	});

	/* POSTS RELACIONADOS */

	$('#relacionados.lojas .owl-carousel').owlCarousel(
	{
		margin:30,
		loop: false,
	    nav: true,
	    mouseDrag: true,
		touchDrag: true,
		navSpeed: 1000,
		responsive:{
	        0:{
	            //center:true,
	            items:1,
	            margin:5,
	            center:true,
	        },
	        581:{
	            items:3
	        },
	        1180:{
	            items:4
	        }
	    }
	});

	$('#relacionados.cinema .owl-carousel').owlCarousel(
	{
		margin: 30,
		loop: false,
	    nav: true,
	    mouseDrag: true,
		touchDrag: true,
		navSpeed: 1000,
		responsive:{
	        0:{
	            //center:true,
	            items:1,
	            margin:5,
	            loop: false,
	            center:true,
	            autoWidth:false,
	        },
	        580:{
	            items: 3,
	        },
	        740:{
	            items: 2,
	        },
	        1024:{
	            items: 3
	        }
	    }
	});
	
	$('#relacionados.projetos .owl-carousel').owlCarousel(
	{
		margin: 30,
		loop: false,
	    nav: true,
	    mouseDrag: true,
		touchDrag: true,
		navSpeed: 1000,
		responsive:{
	        0:{
	            //center:true,
	            items:1,
	            margin:5,
	            loop: false,
	            center:true,
	            autoWidth:false,
	        },
	        580:{
	            items: 3,
	        },
	        740:{
	            items: 2,
	        },
	        1024:{
	            items: 3
	        }
	    }
	});
	
	/* SWIPER DEPOIMENTOS */

	$('#depoimentos .owl-carousel').owlCarousel(
	{
		margin: 30,
		items: 3,
		loop: true,
	    nav: true,
	    autoHeight:true,
	    mouseDrag: true,
		touchDrag: true,
		navSpeed: 1000,
		responsive:{
	        0:{
	            items:1,
	            margin:0,
	        },
	        740:{
	            items: 2,
	            margin: 40,
	        },
	        1024:{
	            items: 3,
	            margin: 30,
	        }
	    }
	});
}