/* ----------------------------------------------------------
GOOGLE MAPS
-------------------------------------------------------------*/

var gMaps = function()
{
	if($('#map').length > 0)
    {
        var apiKey = parseFloat($('.searchRote').attr('data-key'));

        function getUserAddress(lat,long)
        {
            var getAddress = $.ajax(
            {
                type: "get",
                url: 'https://maps.googleapis.com/maps/api/geocode/json?latlng='+lat+','+long+'&sensor=true&key='+apiKey,
                dataType: "json"
            });

            getAddress.done(function(result,statusText,xhr)
            {
                if(xhr.status == 200)
                {
                    $("#autocomplete").val(result.results[0].formatted_address)
                }
            });
        }

        function geolocate()
        {
            if (navigator.geolocation)
            {
                navigator.geolocation.getCurrentPosition(function(position,xhr,teste)
                {
                    var geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };

                    var circle = new google.maps.Circle({
                        center: geolocation,
                        radius: position.coords.accuracy
                    });

                    autocomplete.setBounds(circle.getBounds());

                    getUserAddress(position.coords.latitude,position.coords.longitude);
                });
            }
        }

        var autocomplete;

        function initAutocomplete()
        {
          autocomplete = new google.maps.places.Autocomplete( (document.getElementById('autocomplete') ), {types: ['geocode']} );
        }

        initAutocomplete();

        var styleArray = [{
            featureType: "all",
            stylers: [
            { saturation: -10 }
        ]},{
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [
                { hue: "#C9A343" },
                { saturation: 100 }
            ]
        },{
            featureType: "poi.business",
            elementType: "labels",
        }];

        var map;
        var lat = parseFloat($('.searchRote').attr('data-lat'));
        var long = parseFloat($('.searchRote').attr('data-long'));
        var myLatLng = {lat: lat, lng: long};
        var directionsDisplay; // Instanciaremos ele mais tarde, que serÃ¡ o nosso google.maps.DirectionsRenderer
        var directionsService = new google.maps.DirectionsService();

        function initMap() {

            directionsDisplay = new google.maps.DirectionsRenderer(); // Instanciando...

            map = new google.maps.Map(document.getElementById('map'), {
                center: myLatLng,
                zoom: 17,
                styles: styleArray,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            });

            var marker = new google.maps.Marker({
                map: map,
                position: myLatLng,
                //title: 'Hideaway',
                //icon: hc.path+'images/marcador.png'
            });

            directionsDisplay.setMap(map);
            directionsDisplay.setPanel(document.getElementById("trajeto-texto"));

            if($(window).width() < 1024){
                map.setOptions({
                    draggable: false,
                    disableDoubleClickZoom: false,
                    rotateControl: false,
                    scaleControl: false,
                    scrollwheel: false
                });
            }

        }

        initMap();

        $("form#formTraceRoute").submit(function(event)
        {

           event.preventDefault();

           var enderecoPartida = $("#autocomplete").val();
           //var enderecoChegada = $("#txtEnderecoChegada").val();
           var enderecoChegada = myLatLng;
           

           var request = { // Novo objeto google.maps.DirectionsRequest, contendo:
              origin: enderecoPartida, // origem
              destination: enderecoChegada, // destino
              travelMode: google.maps.TravelMode.DRIVING // meio de transporte, nesse caso, de carro
           };

           directionsService.route(request, function(result, status) {
              if (status == google.maps.DirectionsStatus.OK) { // Se deu tudo certo
                 directionsDisplay.setDirections(result); // Renderizamos no mapa o resultado
              }
           });
        });

        geolocate();

        $("input#autocomplete").on('focus',function()
        {
            initAutocomplete();
        });
    }
}