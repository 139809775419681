
/* ----------------------------------------------------------
FILTRO LOJAS E RESTAURANTES
------------------------------------------------------------- */

var filterBox 			= $('#lojas .filtros');
var filterBoxInput 		= $('#lojas .filtros form input:not([type="text"])');
var filterBoxInputSegmentos = $('#lojas .filtros #filterSegmentoForm input');
var filterBoxInputText 	= $('#lojas .filtros form input[type="text"]');
var storeList			= $('#lojas #lojasList');
var storeListItem		= $('#lojas #lojasList li');
var filterFlags			= $('.listaFiltrosMobile');

var filterNameFormObj	= $('#filterNameForm');
var storeListError		= $('#lojas #lojasList .error');

var resetSegmentos= $('.resertSegmentosFilter');

/* DETALHES LOJAS */

function detalhesLOjas()
{
	var otherDatails = $('#lojasList li');
	
	$('a.moreDetails, #lojas #lojasList li div.titulo').on('click',function(e)
	{
		e.preventDefault();

		var parent = $(this).parent('li').toggleClass('opened');
	});
}

/* CRIA E REMOVE FLAGS NA VERSÃƒO CELULAR PORTRAIT */

function removeflags()
{
	filterFlags.find('ul').find('li').each(function()
	{
		var input = filterBox.find('input'+$(this).data('field'));

		$(this).on('click',function()
		{
			input.prop('checked',false);
			$(this).remove();
			arrayFilters('checkbox');
		});
	})
}

function createFlags(filtros)
{

	var html = '';

	for(var i = 0; i < filtros.length; i++)
	{
		var label = filtros[i].title;
		// var label = '';
		// if(filtros[i].data == 'segmento') {
		// 	label = filtros[i].title;
		// } else {
		// 	label = filtros[i].data.replace('-' , ' ' );
		// 	label = label.charAt(0).toUpperCase() + label.slice(1);
		// }
		
		html += '<li class="noDot" data-field="#'+filtros[i].id+'">';
			html += '<span>'+label+'</span>';
			html += '<i class="icon-cancel"></i>';
		html += '</li>';
	}

	filterFlags.find('ul').html(html);

	removeflags();
}

/* MORE FILTERS */

function moreFilters()
{
	$('#lojas .filtros h3.more').on('click',function(e)
	{
		e.preventDefault();

		if( !$(this).hasClass('opened') )
		{
			$(this).addClass('opened');
			$('#lojas .filtros > .colapseFilters').removeClass('opened').addClass('opened');
		}
		else
		{
			$(this).removeClass('opened');
			$('#lojas .filtros > .colapseFilters').removeClass('opened');
		}
	});
	
	$('#lojas .filtros .filtroSegmento h4').on('click',function(e)
	{
		e.preventDefault();

		if( !$(this).hasClass('opened') )
		{
			$(this).addClass('opened');
			$('#lojas .filtros .filtroSegmento .colapseFilters').removeClass('opened').addClass('opened');
		}
		else
		{
			$(this).removeClass('opened');
			$('#lojas .filtros .filtroSegmento .colapseFilters').removeClass('opened');
		}
	});
}


/* LIMPA STRING DO NOME DA LOJA */

function accentsTidy(s)
{
	var r=s.toLowerCase();
	//r = r.replace(new RegExp(/\s/g),"");
	r = r.replace(new RegExp(/[Ã Ã¡Ã¢Ã£Ã¤Ã¥]/g),"a");
	r = r.replace(new RegExp(/Ã¦/g),"ae");
	r = r.replace(new RegExp(/Ã§/g),"c");
	r = r.replace(new RegExp(/[Ã¨Ã©ÃªÃ«]/g),"e");
	r = r.replace(new RegExp(/[Ã¬Ã­Ã®Ã¯]/g),"i");
	r = r.replace(new RegExp(/Ã±/g),"n");                
	r = r.replace(new RegExp(/[Ã²Ã³Ã´ÃµÃ¶]/g),"o");
	r = r.replace(new RegExp(/Å“/g),"oe");
	r = r.replace(new RegExp(/[Ã¹ÃºÃ»Ã¼]/g),"u");
	r = r.replace(new RegExp(/[Ã½Ã¿]/g),"y");
	// r = r.replace(new RegExp(/\W/g),"");
	return r;
};

/* EXECUTA FILTROS */

function FiltrarPor()
{
	this.nomeLoja = function(value)
	{
		var strNormalized = accentsTidy(value);

		filterBoxInputText.addClass('filtered');
		storeListItem.removeClass('deactived').addClass('visible');

		var strNormalized = accentsTidy(value).toLowerCase();

		storeListItem.each(function()
		{
			var title = accentsTidy( $(this).data('slug') ).toLowerCase();

			if(title.indexOf( strNormalized ) < 0 )
			{
				$(this).removeClass('visible').addClass('deactived');
			}
		});
		
		var urlPath = window.location.pathname.split('/');
		history.pushState({}, '', './'+urlPath[urlPath.length-1]);

		var totalStoreListVisible = 0;

		storeListItem.each(function()
		{
			if( $(this).hasClass('visible') )
			{
				totalStoreListVisible++;
			}
		});

		if(totalStoreListVisible <= 0)
		{
			storeListError.addClass('visible').html('<p>'+storeListError.data('msg')+'</p>');
		}
		else
		{
			storeListError.removeClass('visible').html('');
		}
	}

	this.atributos = function(filtros)
	{
		storeListError.removeClass('visible').html('');
		storeListItem.removeClass('deactived').addClass('visible');

	
		var arrayQueryString = [];
		var wasSegmento = 0;
		
		storeListItem.each(function()
		{
			for(var i = 0; i < filtros.length; i++)
			{
				if(filtros[i].types == 'beneficios') {
					if($(this).data( filtros[i].id ) == false ) {
						$(this).removeClass('visible').addClass('deactived');	
					} else if($(this).data( filtros[i].id) == true) {
						arrayQueryString[filtros[i].data ] = true;
					}
				} else if(filtros[i].types == 'segmentos') {
					if($(this).data( filtros[i].data ) != filtros[i].val) {
						$(this).removeClass('visible').addClass('deactived');
					} else if($(this).data( filtros[i].data ) == filtros[i].val) {
						arrayQueryString['segmento'] = $(this).data( filtros[i].data ) ;
					}
				} else if( filtros[i].types != 'segmentos') {
					if($(this).data( filtros[i].data ) == false ) {
						$(this).removeClass('visible').addClass('deactived');	
					}
				}
			}
		});

		// CriaÃ§Ã£o da string da query string
		var originQueryString = '';
		Object.entries(arrayQueryString).forEach(function (item, index) {
			var key = item[0];
			var value = item[1];
			
			if(index == 0 ) {
				originQueryString = originQueryString + key + '=' + value;
			} else {
				originQueryString = originQueryString + '&' + key + '=' + value;
			} 
		});	
		
		if(originQueryString) {
			var urlPath = window.location.pathname.split('/');
			history.pushState({}, '', './'+urlPath[urlPath.length-1]+'?'+originQueryString );
		} else {
			var urlPath = window.location.pathname.split('/');
			history.pushState({}, '', './'+urlPath[urlPath.length-1]);
		}

		var totalStoreListVisible = 0;

		storeListItem.each(function()
		{
			if( $(this).hasClass('visible') )
			{
				totalStoreListVisible++;
			}
		});

		if(totalStoreListVisible <= 0)
		{
			storeListError.addClass('visible').html('<p>'+storeListError.data('msg')+'</p>');
		}
	}
}

/*
CRIA ARRAY COM ITENS SELCIONADOS NOS FILTROS
	-> DEPOIS CHAMA FUNÃ‡ÃƒO (ACIMA) QUE EXECUTA FILTRAGEM.
	-> SE FOR MOBILE, TAMBÃ‰M CHAMA FUNÃ‡ÃƒO QUE CRIA A LISTA INDICADORA DOS FILTROS
*/

function arrayFilters(type)
{
	var filtrar = new FiltrarPor();

	if(type == 'checkbox')
	{
		var allFilters = [];

		filterBoxInput.each(function()
		{
			if( $(this).is(':checked') )
			{
				allFilters.push({
				    id		: $(this).attr('id'),
				    data 	: $(this).data().type,
				    types 	: $(this).data().types,
				    val  	: $(this).val(),
				    title	: $(this).attr('title'),
				});

				if( $(window).width() <= 580 )
				{
					createFlags(allFilters);
				}
			}
		});

		filtrar.atributos(allFilters);
	}

	if(type == 'text')
	{
		filterBoxInputText.each(function()
		{
			filtrar.nomeLoja( $(this).val() );
		});
	}
}

/* ATUALIZAR FILTROS */

function atualizarFiltrosCheck() 
{
	filterBox.find('#filterNameForm').find('input[type="reset"]').trigger('click');
	filterBoxInputText.removeClass('filtered');
	arrayFilters('checkbox');
	
	filterBoxInputSegmentos.each(function() {
		if( $(this).is(':checked') ) {
			resetSegmentos.removeClass('hide');
			return false;
		}
	});
}

function limparCheckFiltrosSemFiltrar() {
		
	filterBoxInput.prop('checked',false);
	filterFlags.find('ul').html('');
	storeListItem.removeClass('deactived').addClass('visible');
	storeListError.removeClass('visible').html('');
	
	resetSegmentos.addClass('hide');
} 

function limparCheckFiltros() {
	limparCheckFiltrosSemFiltrar();
	arrayFilters('checkbox');
}

function limparSegmentosFiltrosSemFiltrar() {
	filterBoxInputSegmentos.prop('checked',false);
	filterFlags.find('ul').html('');
	storeListItem.removeClass('deactived').addClass('visible');
	storeListError.removeClass('visible').html('');

	resetSegmentos.addClass('hide');
}

function limparSegmentosFiltros() {
	limparSegmentosFiltrosSemFiltrar();
	arrayFilters('checkbox');
}

/* VERIFICA QUERY STRING */

function checkQueryString() {
	if(window.location.href.split('?')[1])
	{
		var query = window.location.href.split('?')[1];

		var params = query.split('&');
		
		params.forEach(function (item, index) {

			var _params = item.split('=');
			var _param_index = _params[0];
			var _param_valor = _params[1];
			
			if(_param_valor) {
				switch(_param_index) {
					case 'segmento':
		    			filterBox.find('.filtroSegmento').find('form input[type="radio"]').each(function() {
							if( $(this).val() == decodeURIComponent(_param_valor) ) {
								
								$(this).prop('checked',true);
								
								atualizarFiltrosCheck();
							}
						});
					    break;
					default:
						filterBox.find('.filtroBeneficio').find('form input[type="checkbox"]').each(function() {
							if($(this).data("type") == _param_index && _param_valor == 'true') {

								$(this).prop('checked',true);
								
								atualizarFiltrosCheck();	
							}
						});
					    break;
				}
						
			}
		});
		
	}

}


/* INICIALIZA FILTROS */

var Filtros = function()
{
	if( filterBox )
	{
		/* INPUT TEXT */

		filterBoxInputText.keyup(function(e)
		{
			if($(this).val().length >= 2)
			{
				limparCheckFiltrosSemFiltrar();
				arrayFilters('text');
			}
			
			if($(this).val().length <= 2)
			{
				limparCheckFiltrosSemFiltrar();
			}
	    });

		/* INPUT CHECKBOX */
		
		filterBoxInput.change(function()
		{
			atualizarFiltrosCheck();
	
			if( $(this).is('[type="radio"]') ) {
				resetSegmentos.removeClass('hide');
			}
			if($(window).width() <= 740) {
				$('#lojas .filtros h3.more').trigger('click');	
			}
		});

		/* CRIA FLAGS NO MOBILE e FECHA O MAIS FILTROS */

		if( $(window).width() <= 740 )
		{
			filterFlags.html('<ul></ul>');

			moreFilters();			
		}
		
		/* SUBMIT TEXT FIELD */
		
		filterNameFormObj.on('submit',function(e)
		{
			e.preventDefault();
			
			$('html,body').stop().animate({ scrollTop : $('#lojasList').offset().top - 90},800);
		});

		/* RESET FORM */

		$('.resertFilter').on('click',function()
		{
			filterBox.find('#filterNameForm').find('input[type="reset"]').trigger('click');
			filterBoxInputText.removeClass('filtered');

			storeListError.removeClass('visible').html('');
			storeListItem.removeClass('deactived').addClass('visible');
		});
		
		resetSegmentos.on('click',function()
		{
			limparSegmentosFiltros();
		});

		/* CHECA URL QUERY STRING */
		checkQueryString();
	}

	if( $('#lojasList') )
	{
		detalhesLOjas();
	}
}