
/* ------------------------------------------
DEVICE IDNETIFY
--------------------------------------------*/

var deviceDiscover = function()
{

	// ----------------------------------------------

	// DOCUMENTAÃ‡ÃƒO DO PLUGIN:
	// http://hgoebl.github.io/mobile-detect.js/

	// ----------------------------------------------

	var md = new MobileDetect(window.navigator.userAgent);

	if(md.mobile() == null)
	{
		$('.btnAndroid, .btnIOs').css({'display':'none'});
		$('.btnDesktop').css({'display':'block'});
	}
	else
	{
		if(md.os() == 'iOS')
		{
			$('.btnAndroid, .btnDesktop').css({'display':'none'});
			$('.btnIOs').css({'display':'block'});
		}
		else if(md.os() == 'AndroidOS')
		{
			$('.btnIOs, .btnDesktop').css({'display':'none'});
			$('.btnAndroid').css({'display':'block'});
		}
		else
		{
			$('footer#mainFooter .blocos.app, #appBar .appButton').css({'display':'none'});
			$('#appBar ul').css({'width':'100%'});
		}
	}

	$('#appBar').stop().animate({opacity:1},300);
}

/* ------------------------------------------
IMAGE LAZY LOAD
--------------------------------------------*/

var imageLazyLoad = function(obj,imagem)
{
	if(!obj)
	{
		$('.imgLazyLoad').each(function()
		{
			if(!$(this).hasClass('responsiveImage'))
			{
				if($(this).find('.img').length > 0)
				{
					$(this).find('.img').css({'background-image':'url(&#39;'+$(this).data('img')+'&#39;)'});
				}
				else
				{
					$(this).prepend('<div class="img" style="background-image:url(&#39;'+$(this).data('img')+'&#39;)"></div>');
				}
			}
		});
	}
	else
	{

		if(obj.find('.img').length > 0)
		{
			obj.find('.img').css({'background-image':'url('+imagem+')'});
		}
		else
		{
			
			obj.prepend('<div class="img" style="background-image:url('+imagem+')"></div>');
		}
	}
}

/* ----------------------------------------------------------
DEFINE IMAGENS BACKGROUND DO BANNER RESPONSIVAS
-------------------------------------------------------------*/

var responsiveImages = function()
{
	function returnMedia(medidas)
	{
		if($(window).width() >= $(window).height())
		{
			/* MEDIAS LANDSCAPES */

			if($(window).width() > 1366)
			{
				imageLazyLoad(medidas.boxImage,medidas.desktop_X);
			}

			if($(window).width() <= 1366 && $(window).width() >= 1024)
			{
				imageLazyLoad(medidas.boxImage,medidas.tablet_X);
			}

			if($(window).width() < 1024)
			{
				imageLazyLoad(medidas.boxImage,medidas.mobile_X);
			}
		}
		else
		{
			/* MEDIAS PORTRAITS */

			if($(window).width() <= 1024 && $(window).width() > 540)
			{
				imageLazyLoad(medidas.boxImage,medidas.tablet_Y);
			}

			if($(window).width() <= 540)
			{
				imageLazyLoad(medidas.boxImage,medidas.mobile_Y);
			}
		}

	}

	$('.responsiveImage').each(function()
	{
		returnMedia(
		{
			boxImage : $(this),
			desktop_X : $(this).attr('data-media-1920'),
			tablet_X : $(this).attr('data-media-1366'),
			mobile_X : $(this).attr('data-media-812'),
			tablet_Y : $(this).attr('data-media-1024'),
			mobile_Y : $(this).attr('data-media-609'),
		});
	});
}

/* ----------------------------------------------------------
SCROLLTO
-------------------------------------------------------------*/

var buttonScrollTo = function()
{
	$('a.scrollTo').each(function()
	{
		$(this).on('click',function(e)
		{
			e.preventDefault();
			$('a.scrollTo').removeClass('activeScroll');
			$(this).addClass('activeScroll');
			$('html,body').stop().animate({ scrollTop : $($(this).attr('href')).offset().top - 170},1000);
		});
	});

	if($('main#lazer').length > 0 && window.location.hash)
	{
		var altura = $(window.location.hash).offset().top;

		$('html,body').stop().animate({ scrollTop : altura - 100},1000);
	}
}

/* ----------------------------------------------------------
INSTAGRAM
-------------------------------------------------------------*/

var instagramTool = function()
{
	if($('section#instagram'))
	{
		var phpPath = $('section#instagram').data('path')+'instashow/api/index.php';

		$('.my-instashow').eappsInstagramFeed({
	        api: phpPath,
	        source: $('section#instagram').data('profile'),
	        width: 'auto',
	        layout: 'slider',
	        gutter: 20,
	        columns: 6,
	        rows: 1,
	        sliderAutoplay: 4,
	        sliderSpeed: 1.5,
	        sliderArrows: true,
	        lang: 'pt-br',
	        responsive:{
		        560:{
		        	gutter: 5,
		            columns:2
		        },
		        740:{
		        	gutter: 10,
		            columns:4
		        },
		        1000:{
		            columns:5
		        }
		    }
	    });
	}
}

/* ----------------------------------------------------------
PÃGINAS COM ABAS E FORMULÃRIOS SOB DEMANDA
-------------------------------------------------------------*/

function formSeletor(type)
{
	if(type && type != '')
	{
		if(type == 'lojista')
		{
			$('form.seletorFormForm').removeClass('opened');
			$('#formComercializacaoLojista').addClass('opened');
			
			// console.log('ifLojista');
			// console.log(captchaComercializacaoLojista);
			// enviaFormularios
			// (
			// 	{
			// 		method 		: 'post',
			// 		formulario 	: 'formComercializacaoLojista',
			// 		recaptcha 	: captchaComercializacaoLojista
			// 	}
			// );
		}

		if(type == 'anunciar')
		{
			$('form.seletorFormForm').removeClass('opened');
			$('#formComercializacaoAnunciar').addClass('opened');
			
			// console.log('ifAnunciar');
			// console.log(captchaComercializacaoLojista);
			// enviaFormularios
			// (
			// 	{
			// 		method 		: 'post',
			// 		formulario 	: 'formComercializacaoAnunciar',
			// 		recaptcha 	: captchaComercializacaoAnunciar
			// 	}
			// );
		}
		
		// if(type == 'newsletter') 
		// {
			// enviaFormularios
			// (
			// 	{
			// 		method 		: 'post',
			// 		formulario 	: 'formNewsletter',
			// 		recaptcha 	: captchaFormNewsletter
			// 	}
			// );
		// }
		
		// if(type == 'contato') 
		// {
			// enviaFormularios
			// (
			// 	{
			// 		method 		: 'post',
			// 		formulario 	: 'formContato',
			// 		recaptcha 	: captchaFormContato
			// 	}
			// );
		// }
	}
}

var TabContentsController = function()
{
	if( $('#cinema').length > 0 )
	{
		var hashOrigin 	= window.location.hash.replace('#','').split('/');
		var urlHash 	= hashOrigin[0];
		var thehAncor 	= hashOrigin[1];
	}
	else
	{
		var hashOrigin = window.location.hash.replace('#','').split('-');
		var urlHash = hashOrigin[0];
	}

	var faleConosco = $('.tabContentSection'),
		tabNav 		= faleConosco.find('.tabNav'),
		tabContent 	= faleConosco.find('.tabContent');

	/* FUNÃ‡Ã•ES */

	function swipeCinema()
	{
		var cinemaOWL = $('#cinema .owl-carousel').owlCarousel(
		{
			margin:30,
			items:7,
			center:false,
			loop: false,
		    nav: false,
		    mouseDrag: false,
			touchDrag: false,
			navSpeed: 1000,
			onInitialized  : callbackCinemaSections,
			responsive:{
		        0:{
		            items:4,
		            margin:1,
		            nav: true,
		            mouseDrag: true,
		            touchDrag: true,
		            autoWidth: true,
		        },
		        568:{
		        	margin:1,
		            items:7
		        },
		        740:{
		            margin:10,
		        },
		        1180:{
		            items:7
		        }
		    }
		});

		function callbackCinemaSections()
		{
			$('#cinema .tabNav.owl-carousel').stop().animate({opacity:1},200);
			//setCurrentTabNav();
		}
	}

	function callBackFunctions()
	{
		$('#formContato').submit(function(e)
		{
			e.preventDefault();
		});
	}

	function accordeon()
	{
		$('.accordeon dt').click(function()
		{
			$(this).toggleClass('opened');
		});
	}

	function openCurrentTabContent(hash)
	{
		if(hash && hash != '')
		{
			tabContent.find('#'+hash).css({'display':'block'}).stop().animate({opacity:1},300);

			if( hashOrigin[1] && hashOrigin[1] != '' )
			{
				formSeletor(hashOrigin[1])
			}

			if( thehAncor && thehAncor != '' )
			{
				var altura = tabContent.find('#'+hash).find('li[data-movie="'+thehAncor+'"]').offset().top;

				$('html,body').stop().animate({ scrollTop : altura - 70},1000);
			}
		}
		else
		{	
			tabContent.find('.tab:first-child').css({'display':'block'}).stop().animate({opacity:1},300);
		}
	}

	function resetTabContent(hash)
	{
		tabContent.find('.tab').stop().animate({opacity:0},200,function()
		{
			$(this).css({'display':'none'});
		});
		
		if(hash && hash != '')
		{
			openCurrentTabContent(hash);
		}
		else if(urlHash && urlHash != '')
		{
			openCurrentTabContent(urlHash);
		}
		else
		{
			openCurrentTabContent();
		}
	}

	function setCurrentTabNav()
	{
		//tabNav.find('li').removeClass('current-content');

		if(urlHash && urlHash != '')
		{	
			tabNav.find('li a').each(function()
			{
				if($(this).attr('href').replace('#','') == urlHash)
				{
					$(this).parent('li').addClass('current-content');
				}
			});
		}
		else
		{
			tabNav.find('li:first-child').addClass('current-content');
		}

		if(tabNav.hasClass('owl-carousel'))
		{
			swipeCinema();
		}

		resetTabContent();
	}

	/* START FUNÃ‡Ã”ES */

	accordeon();
	setCurrentTabNav();
	
	/* START EVENTOS */

	tabNav.find('li.deactivated a').on('click',function()
	{
		return false;
	});

	
	tabNav.find('li:not(.deactivated) a').on('click',function(e)
	{
		e.preventDefault();
		resetTabContent($(this).attr('href').replace('#',''));
		history.pushState(null, null, $(this).attr('href') );
		tabNav.find('li').removeClass('current-content');
		$(this).parent('li').addClass('current-content');
	});

	if(urlHash == 'comercializacao')
	{
		if(window.location.hash.replace('#','').split('-')[1])
		{
			$('.seletorForm div.select select option').each(function()
			{
				if( window.location.hash.replace('#','').split('-')[1] == 'lojista' && $(this).attr('value') == '#formComercializacaoLojista' )
				{
					$(this).attr('selected','selected');
				}
				if( window.location.hash.replace('#','').split('-')[1] == 'anunciar' && $(this).attr('value') == '#formComercializacaoAnunciar' )
				{
					$(this).attr('selected','selected');
				}
			});
		}
	}

	$('.seletorForm div.select select').on('change',function()
	{
		$('form.seletorFormForm').find('.alertsMessages').stop().animate({height : 0, opacity : 0},300);

		$('form.seletorFormForm').removeClass('opened').find('input[type="reset"]').trigger('click');
		
		if( $(this).val() == '#formComercializacaoLojista' )
		{
			formSeletor('lojista');
			window.location.hash = 'comercializacao-lojista';
		}
		else if( $(this).val() == '#formComercializacaoAnunciar' )
		{
			formSeletor('anunciar');
			window.location.hash = 'comercializacao-anunciar';
		}
		else
		{
			window.location.hash = 'comercializacao';
		}
		
	});
}


/* ----------------------------------------------------------
LINKS RELACIONADOS
-------------------------------------------------------------*/

var linkRelacionados = function()
{
	$('#relacionados.lojas li.item').each(function()
	{
		var href = $(this).find('.imgLazyLoad a').attr('href');

		$(this).find('.legenda').css({'cursor':'pointer'}).on('click',function()
		{
			window.location.href = href;
		});
	});
}

/* ----------------------------------------------------------
CAROUSEL OUTROS SHOPPINS - COMERCIALIZAÃ‡ÃƒO E MÃDIA
-------------------------------------------------------------*/
var carouselOutrosShoppings = function(){
	alert('carrossel 1')
	$('section#outrosShoppings .owl-carousel').owlCarousel({
		loop:true,
		margin:10,
		nav:true,
		dots:false,
		responsive:{
			0:{
				items:1
			},
			600:{
				items:2
			},
			1000:{
				items:3
			},
			1300:{
				items:4
			}
		}
	})
}	

/* ----------------------------------------------------------
INICIA SCRIPTS
-------------------------------------------------------------*/

jQuery(function()
{
	$ = jQuery;
	
	topBarScroll();
	deviceDiscover();
	TabContentsController();

	var swiper 		= new sliders();
	var climaTempo 	= new weather();
	var barraTopo 	= new ControlesTopBar();
	var filtros 	= new Filtros();

	$('.tel-mask').mask('(00) 0000-00009');
	$('.cel-mask').mask('00000-0009');
	$('.cnpj-mask').mask('00.000.000/0000-00');
	$('.cep-mask').mask('00000-000');
	$('.cpf-mask').mask('00000000009');
	$('.date-mask').mask('00/00/0000');
	$('.rg-mask').mask('0000000009');

	$(window).resize(function()
	{
		deviceDiscover();
		responsiveImages();
		swiper;
		filtros;
		instagramTool();
	});

	window.onload = function()
	{
		imageLazyLoad();
		responsiveImages();
		buttonScrollTo();
		instagramTool();
		//formSeletor();
		linkRelacionados();
		var googleMaps 		= new gMaps();
		var clubeempresa 	= new ClubeEmpresa();
		var cookiePolicy 	= new CookiePolicy();
		
		if( $('article.lojista').length > 0 )
		{
			formSeletor('lojista');
			formSeletor('anunciar');
		}
	
		if( $('section#outrosShoppings').length > 0 ){
			alert('carrossel 0')
			carouselOutrosShoppings();
		}
		// setTimeout(function()
		// {
		// 	if( $('article.lojista').length > 0 )
		// 	{
		// 		formSeletor('lojista');
		// 		formSeletor('anunciar');
		// 	}
	
		// 	if( $('#formContato').length > 0 )
		// 	{
				
		// 		enviaFormularios
		// 		(
		// 			{
		// 				method 		: 'post',
		// 				formulario 	: 'formContato',
		// 				recaptcha 	: captchaFormContato
		// 			}
		// 		);
		// 	}
		
		// 	if( $('#formNewsletter').length > 0 )
		// 	{
		// 		enviaFormularios
		// 		(
		// 			{
		// 				method 		: 'post',
		// 				formulario 	: 'formNewsletter',
		// 				recaptcha 	: captchaFormNewsletter
		// 			}
		// 		);
		// 	}
		// }, 2000);
	}();

	document.addEventListener('keydown',function(e)
	{
		if (e.key === 'Tab') {
			$('body').addClass('tabKey');
			$('.overContent').css({'display' : 'none'});
			$('.filmeTitle').css({'display' : 'block'});
		}
	});

	$(".nClose").click(function()
	{
        document.cookie = "aceptCookie=True";
        $( ".cTB" ).fadeOut( "slow" );
    });

    $('.formularioStyle div.select select#goToShopping').on('change',function()
    {
    	window.open($(this).val(), '_blank');
    	//$('form#changeShopping').attr( 'action', $(this).val()+"?origem=seletor-footer" ).submit();
	})
	
	// TABS
	$( "#tabs" ).tabs({
		collapsible: true,
		active: false,
		//active: false,
		show: { effect: "fade", duration: 800 }
	});
});
