/* ----------------------------------------------------------
CLIMA TEMPO
-------------------------------------------------------------*/

var weather = function()
{
	// var origin = window.location.origin.split('//')[1],
	var boxClima = $('#mainHeader .weather');

	function setClimaIcon(iconname)
	{
		switch(iconname)
		{
			default: 	return '<i class="wi wi-day-sunny"></i>'; break;

			case '01d' : return '<i class="wi wi-day-sunny"></i>'; break;
			case '01n' : return '<i class="wi wi-night-clear"></i>'; break;
			case '02d' : return '<i class="wi wi-day-sunny-overcast"></i>'; break;
			case '02n' : return '<i class="wi wi-night-alt-partly-cloudy"></i>'; break;
			case '03d' : return '<i class="wi wi-cloud"></i>'; break;
			case '03n' : return '<i class="wi wi-cloud"></i>'; break;

			case '04d' : return '<i class="wi wi-day-cloudy"></i>'; break;
			case '04n' : return '<i class="wi wi-night-alt-cloudy"></i>'; break;
			case '09d' : return '<i class="wi wi-day-sprinkle"></i>'; break;
			case '09n' : return '<i class="wi wi-night-alt-sprinkle"></i>'; break;
			case '10d' : return '<i class="wi wi-day-storm-showers"></i>'; break;
			case '10n' : return '<i class="wi wi-night-alt-storm-showers"></i>'; break;

			case '11d' : return '<i class="wi wi-day-sleet-storm"></i>'; break;
			case '11n' : return '<i class="wi wi-night-alt-snow-thunderstorm"></i>'; break;
			case '13d' : return '<i class="wi wi-day-snow"></i>'; break;
			case '13n' : return '<i class="wi wi-night-alt-snow"></i>'; break;
			case '50d' : return '<i class="wi wi-day-light-wind"></i>'; break;
			case '50n' : return '<i class="wi wi-strong-wind"></i>'; break;
		}
	}

	function getJasonClima(jsonname)
	{
		$.ajax(
		{
            url : jsonname,
            dataType: 'jsonp',
            success : function (response)
            {
            	if(response.main)
            	{
	            	var html = setClimaIcon(response.weather[0].icon);
	            		html += '<span>'+parseInt(response.main.temp)+'&deg;</span>';

	            	boxClima.html(html);
	            }
	            else
	            {
	            	boxClima.css({'display':'none'});
	            }
            }
        });
	}

	if( ( $('#mainHeader .barContent .tools.weather').data('city') && $('#mainHeader .barContent .tools.weather').data('city') != '' ) )
	{
		if( ( $('#mainHeader .barContent .tools.weather').data('appid') && $('#mainHeader .barContent .tools.weather').data('appid') != '' ) )
		{
			var city = $('#mainHeader .barContent .tools.weather').data('city'),
				appid = $('#mainHeader .barContent .tools.weather').data('appid');

			getJasonClima('https://api.openweathermap.org/data/2.5/weather?q='+city+'&appid='+appid+'&units=metric');
		}		
	}

	// if(origin)
	// {
		//if(origin == 'pkl.argomultisites.com' || origin == 'shoppingparklagos.gm5.com.br' || origin == 'shoppingparklagos.com.br')
		//{
			
		//}
	// }
	// else
	// {
	// 	boxClima.css({'display':'none'});
	// }

}