
/*
VARIÃVEIS
--------------------------------------------*/

var topbar 			= $('#mainHeader .barContent'),
	openBusca 		= topbar.find('.tools.search .actions span.open'),
	closeBusca 		= topbar.find('.tools.search .actions span.close'),
	formBusca	 	= topbar.find('.tools.search form'),
	resetFormBusca	= topbar.find('.tools.search form input[type="reset"]'),
	openHorarios 	= topbar.find('.tools.horarios a.drodown'),
	boxHorarios 	= topbar.find('.tools.horarios'),
	openMenu 		= topbar.find('.tools.menuActions span.openMenu'),
	mainMenu 		= $('#mainHeader #navegacaoPrincipal');

/*
FUNÃ‡Ã•ES
-------------------------------------------- */

function openMainMenu()
{
	closeHorariosBox();
	closeBuscaForm();

	$('.eapps-widget.eapps-instagram-feed-popup').css({'z-index' : 0 , 'height' : 0});
	$('#menuMobileClose').css({'display':'block'});

	if($(window).width() <= 1024)
	{
		$('body').css({'overflow':'hidden'});
	}
	
	mainMenu.addClass('opened');
	openMenu.removeClass('closed');
}

function closeMainMenu()
{
	$('.eapps-widget.eapps-instagram-feed-popup').css({'z-index' : 99999 , 'height' : 'auto'});
	$('#menuMobileClose').css({'display':'none'});

	if($(window).width() <= 1024)
	{
		$('body').css({'overflow':'auto'});
	}

	openMenu.addClass('closed');
	mainMenu.removeClass('opened');
}

function openHorariosBox()
{
	closeMainMenu();
	closeBuscaForm();

	openHorarios.removeClass('closed');
	boxHorarios.addClass('opened');
}

function closeHorariosBox()
{
	openHorarios.addClass('closed');
	boxHorarios.removeClass('opened');
}

function openBuscaForm()
{
	console.log('open busca');
	
	closeMainMenu();
	closeHorariosBox();

	openBusca.removeClass('active');
	closeBusca.addClass('active');
	formBusca.addClass('opened');
}

function closeBuscaForm()
{
	
	openBusca.addClass('active');
	closeBusca.removeClass('active');
	formBusca.removeClass('opened');
	resetFormBusca.trigger('click');
}

function resetSearchError()
{
	formBusca.find('span.error').removeClass('active');
}

function search(value)
{
	if(value.length >= 3)
	{
		return true;
	}
	else
	{
		$(this).next('span.error').text('MÃ­nimo de 3 caracteres').addClass('active');
	}	
}

function topBarScroll()
{
	if($(window).width() > 992)
	{
		$(window).scroll(function()
		{
			var pos = $(document).scrollTop();
			
			if (pos >= 34)
			{
				$("#mainHeader").addClass('scrolled');
			} else {
				$("#mainHeader").removeClass('scrolled');
			}
		});
	}
}

/* ------------------------------------------
TOP BAR
--------------------------------------------*/

var ControlesTopBar = function()
{
	/* SCROLL TOPBAR */

	topBarScroll();

	/* ABRIR BUSCA TOPO */

	resetFormBusca.trigger('click');

	openBusca.on('click',function(e)
	{
		e.preventDefault();

		openBuscaForm();
	});

	closeBusca.on('click',function(e)
	{
		e.preventDefault();

		closeBuscaForm();
	});

	formBusca.find('input#inputbusca').keyup(function(e)
	{
		if(e.keyCode == 13)
	    {
	    	e.preventDefault();
	    	search($(this).val());
	    }
	});

	/* ABRIR HORÃRIOS */

	openHorarios.on('click',function(e)
	{
		e.preventDefault();

		if($(this).hasClass('closed'))
		{
			openHorariosBox();
		}
		else
		{
			closeHorariosBox();
		}
	});

	/* MARCA ACTIVE LINK NO MENU */

	mainMenu.find('li.active-trail > a').each(function(index,value)
	{
		var obj = $(value);
		
		if(!$(value).hasClass('btMenu'))
		{
			var obj = $(value)
			var texto = obj.text();

			obj.html('<span>'+texto+'</span>');
		}
	});

	/* ABRIR MENU HOME */

	openMenu.on('click',function(e)
	{
		e.preventDefault();

		if($(this).hasClass('closed'))
		{
			openMainMenu();
		}
		else
		{
			closeMainMenu();
		}
	});

	$(document).click(function(event)
	{
		if($('#navegacaoPrincipal').hasClass('opened'))
		{ 
			$target = $(event.target);

		  	if(!$target.closest('#navegacaoPrincipal, .tools.menuActions span.openMenu').length && $('#navegacaoPrincipal').is(":visible"))
		  	{
		    	closeMainMenu();
		  	}        
		}
	});

	$('#menuMobileClose').on('click',function()
	{
		closeMainMenu();
	});

	/* RESIZES */

	$(window).resize(function()
	{
		topBarScroll();
	});
}
