
/* ----------------------------------------------------------
FILTRO LOJAS E RESTAURANTES
------------------------------------------------------------- */

var beneficiosFiltrosLojas 		= $('#beneficios.blue .formularioStyle div.select select[name="lojaNome"]'),
	beneficiosFiltrosSegmentos 	= $('#beneficios.blue .formularioStyle div.select select[name="lojaSegmento"]'),
	beneficiosItemList			= $('#beneficios.blue ul li');

var empresasFiltro 		= $('#empresas .formularioStyle div.select select[name="empresaNome"]'),
	empresasList 		= $('#empresas .listEmpresas'),
	empresasitemList	= $('#empresas .listEmpresas ul li'),
	empresasDivLoadMore	= $('#empresas .loadMore'),
	empresasLoadMore	= $('#empresas .loadMore a.btn');


/* ----------------------------------------------------------
FILTRO BENEFÃCIOS
------------------------------------------------------------- */

function filterBeneficions(value,attrData)
{
	if(value && value != '')
	{
		beneficiosItemList.addClass('deactived');

		beneficiosItemList.each(function()
		{
			if( $(this).data(attrData) == value )
			{
				$(this).removeClass('deactived');
			}
		});
	}
	else
	{
		beneficiosItemList.removeClass('deactived');
	}
}

/* ----------------------------------------------------------
EMPRESAS
------------------------------------------------------------- */

/* RENDER EMPRESAS */

function renderSelect()
{
	var html = '';

	for(var t = 0; t < arrEmpresasCasdastradas.length; t++)
	{
		html += '<option value="'+arrEmpresasCasdastradas[t][0]+'">'+arrEmpresasCasdastradas[t][0]+'</option>';
	}

	empresasFiltro.append(html);
}

var totalEmpresasListadas = 0;

function renderEmpresas(init,end,action)
{
	/* ATUALIZA BOTÃƒO LOAD MORE */

	empresasDivLoadMore.css({'display':'block'});

	if(action == 'reset')
	{
		totalEmpresasListadas = 0;

		empresasLoadMore.attr( 'data-init' , '12' ).attr( 'data-end' , '23' );
	}
	else
	{
		var nextInit = parseInt(init) + 12,
			nextEnd	 = parseInt(end) + 12;

		empresasLoadMore.attr( 'data-init' , nextInit.toString() ).attr( 'data-end' , nextEnd.toString() );
	}

	/* CRIA LISTA DE EMPRESAS */

	var idTag = 'itens-'+init+'-'+end;

	var html = '<ul id="'+idTag+'" class="clear" role="list" aria-label="Empresas cadastradas de '+init+' Ã  '+end+'">';

	for(var t = init; t <= end; t++)
	{
		if(arrEmpresasCasdastradas[t])
		{
			html += '<li class="noDot col-xs-6 col-xl-4 col-md-3" data-title="'+arrEmpresasCasdastradas[t][0]+'">';
				html += '<div class="wrapContent">';

					if( arrEmpresasCasdastradas[t][1] && arrEmpresasCasdastradas[t][1] != "" )
					{
						html += '<figure class="thumbnail"><img src="'+arrEmpresasCasdastradas[t][1]+'" alt="Logo da empresa '+arrEmpresasCasdastradas[t][0]+'"></figure>';
					}
					else
					{
						html += '<span class="thumbnail"><i class="icon-picture"></i></span>';
					}
					
					html += '<h3>'+arrEmpresasCasdastradas[t][0]+'</h3>';
				
				html += '</div>';
			html += '</li>';

			totalEmpresasListadas++;
		}
	}

	html += '</ul>';

	empresasList.append(html);

	empresasList.find('ul#'+idTag).focus();

	/* ESCONDE LOAD MORE */

	if( totalEmpresasListadas >= arrEmpresasCasdastradas.length )
	{
		empresasDivLoadMore.css({'display':'none'});
	}
}

function filterEmpresas(value)
{
	if(value == 'reset')
	{
		empresasList.html('');
		renderEmpresas(0,11,'reset');
	}
	else
	{
		for(var i = 0; i < arrEmpresasCasdastradas.length; i++ )
		{
			if(value == arrEmpresasCasdastradas[i][0])
			{
				var html = '<ul id="filtered" class="clear" role="list" aria-label="Empresa selecionada '+arrEmpresasCasdastradas[i][0]+'">';

					html += '<li class="noDot col-xs-6 col-xl-4 col-md-3" data-title="'+arrEmpresasCasdastradas[i][0]+'">';
						html += '<div class="wrapContent">';

							if( arrEmpresasCasdastradas[i][1] && arrEmpresasCasdastradas[i][1] != "" )
							{
								html += '<figure class="thumbnail"><img src="'+arrEmpresasCasdastradas[i][1]+'" alt="Logo da empresa '+arrEmpresasCasdastradas[i][0]+'"></figure>';
							}
							else
							{
								html += '<span class="thumbnail"><i class="icon-picture"></i></span>';
							}
							
							html += '<h3>'+arrEmpresasCasdastradas[i][0]+'</h3>';
						
						html += '</div>';
					html += '</li>';

				html += '</ul>';

				empresasList.html(html);

				empresasList.find('ul#filtered').focus();

				empresasDivLoadMore.css({'display':'none'});
			}
		}
	}
}

/* INICIALIZA FILTROS */

var ClubeEmpresa = function()
{
	if( $('#beneficios.blue') )
	{
		beneficiosFiltrosLojas.change(function()
		{
			beneficiosFiltrosSegmentos.val('');
			filterBeneficions( $(this).val() , 'title' );
		});

		beneficiosFiltrosSegmentos.change(function()
		{
			beneficiosFiltrosLojas.val('');
			filterBeneficions( $(this).val() , 'segmento' );
		});
	}

	var statusArrayEmpresas = ( typeof arrEmpresasCasdastradas != 'undefined' && arrEmpresasCasdastradas instanceof Array ) ? true : false;

	if(statusArrayEmpresas == true)
	{
		renderSelect();
		renderEmpresas(0,11);

		empresasLoadMore.on('click',function(e)
		{
			e.preventDefault();
		
			renderEmpresas( $(this).attr('data-init') , $(this).attr('data-end') , 'click' );
		});

		if( $('#filterEmpresas') )
		{
			empresasFiltro.change(function()
			{
				filterEmpresas( $(this).val() );
			});
		}
	}

	if( $('#formClubeEmpresa') )
	{
		cadastroClubeEmpresa();
	}
}
	